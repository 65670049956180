import { ComponentRef, PageRef } from '@wix/platform-editor-sdk';
import { EditorAppContext } from './types';

interface IComponentData {
  id: string;
  type: string;
  appDefinitionId: string;
  applicationId: string;
  widgetId: string;
}
interface IControllerData {
  type: string;
}

interface IComponentWithData {
  componentRef: ComponentRef;
  componentType: string;
  componentData: IComponentData;
}

interface IControllerWithData {
  controllerRef: ComponentRef;
  controllerData: IControllerData;
}

type IComponentCondition = (value: IComponentWithData) => boolean;
type IControllerCondition = (value: IControllerWithData) => boolean;

async function getComponentData(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
): Promise<IComponentWithData> {
  const { editorSDK, appDefinitionId } = appContext;
  const [componentType, componentData] = await Promise.all([
    editorSDK.components.getType(appDefinitionId, { componentRef }),
    editorSDK.components.data.get(appDefinitionId, { componentRef }),
  ]);

  return {
    componentRef,
    componentType,
    componentData: componentData as IComponentData,
  };
}

async function getControllerData(
  appContext: EditorAppContext,
  controllerRef: ComponentRef,
): Promise<IControllerWithData> {
  const { editorSDK, appDefinitionId } = appContext;
  const controllerData = await editorSDK.controllers.getData(appDefinitionId, {
    controllerRef,
  });
  return { controllerRef, controllerData };
}

export async function findComponent(
  appContext: EditorAppContext,
  componentsRefs: ComponentRef[],
  condition: IComponentCondition,
): Promise<ComponentRef | undefined> {
  for (const componentRef of componentsRefs) {
    if (condition(await getComponentData(appContext, componentRef))) {
      return componentRef;
    }
  }
}

export async function getComponent(
  appContext: EditorAppContext,
  condition: IComponentCondition,
): Promise<ComponentRef | undefined> {
  const { editorSDK, appDefinitionId } = appContext;
  const componentsRefs: ComponentRef[] = await editorSDK.components.getAllComponents(
    appDefinitionId,
  );
  return findComponent(appContext, componentsRefs, condition);
}

export async function filterComponents(
  appContext: EditorAppContext,
  componentsRefs: ComponentRef[],
  condition: IComponentCondition,
): Promise<ComponentRef[]> {
  const componentsWithData: IComponentWithData[] = await Promise.all(
    componentsRefs.map(componentRef =>
      getComponentData(appContext, componentRef),
    ),
  );

  return componentsWithData
    .filter(data => condition(data))
    .map(({ componentRef }) => componentRef);
}

export async function filterComponentsByType(
  appContext: EditorAppContext,
  componentRefs: ComponentRef[],
  componentType: string,
): Promise<ComponentRef[]> {
  return filterComponents(
    appContext,
    componentRefs,
    data => data.componentType === componentType,
  );
}

export async function getComponents(
  appContext: EditorAppContext,
  condition: IComponentCondition,
): Promise<ComponentRef[]> {
  const { editorSDK, appDefinitionId } = appContext;
  const componentsRefs: ComponentRef[] = await editorSDK.components.getAllComponents(
    appDefinitionId,
  );
  return filterComponents(appContext, componentsRefs, condition);
}

export async function getComponentsByType(
  appContext: EditorAppContext,
  componentType: string,
): Promise<ComponentRef[]> {
  return getComponents(
    appContext,
    data => data.componentType === componentType,
  );
}

export async function filterControllers(
  appContext: EditorAppContext,
  controllerRefs: ComponentRef[],
  condition: IControllerCondition,
): Promise<ComponentRef[]> {
  const controllersWithData = await Promise.all(
    controllerRefs.map(controllerRef =>
      getControllerData(appContext, controllerRef),
    ),
  );

  return controllersWithData
    .filter(controllerWithData => condition(controllerWithData))
    .map(({ controllerRef }) => controllerRef);
}

export async function filterControllersByType(
  appContext: EditorAppContext,
  controllerRefs: ComponentRef[],
  controllerType: string,
): Promise<ComponentRef[]> {
  return filterControllers(
    appContext,
    controllerRefs,
    ({ controllerData }) => controllerData.type === controllerType,
  );
}

export async function findController(
  appContext: EditorAppContext,
  controllerRefs: ComponentRef[],
  condition: IControllerCondition,
): Promise<ComponentRef | undefined> {
  for (const controllerRef of controllerRefs) {
    if (condition(await getControllerData(appContext, controllerRef))) {
      return controllerRef;
    }
  }
}

export async function findControllerByType(
  appContext: EditorAppContext,
  controllerRefs: ComponentRef[],
  controllerType: string,
): Promise<ComponentRef | undefined> {
  return findController(
    appContext,
    controllerRefs,
    ({ controllerData }) => controllerData.type === controllerType,
  );
}

export async function getControllerByType(
  appContext: EditorAppContext,
  controllerType: string,
  pageRef?: PageRef,
): Promise<ComponentRef | undefined> {
  const { editorSDK, appDefinitionId } = appContext;
  const controllers = await (pageRef
    ? editorSDK.controllers.listControllers(appDefinitionId, { pageRef })
    : editorSDK.controllers.listAllControllers(appDefinitionId));
  const controllerRefs = controllers.map(({ controllerRef }) => controllerRef);

  return findControllerByType(appContext, controllerRefs, controllerType);
}

export async function getControllersByType(
  appContext: EditorAppContext,
  controllerType: string,
): Promise<ComponentRef[]> {
  const { editorSDK, appDefinitionId } = appContext;
  const controllers = await editorSDK.controllers.listAllControllers(
    appDefinitionId,
  );
  const controllerRefs = controllers.map(({ controllerRef }) => controllerRef);
  return filterControllersByType(appContext, controllerRefs, controllerType);
}

export async function removeComponents(
  appContext: EditorAppContext,
  componentRefs: ComponentRef[],
): Promise<void> {
  const { editorSDK, appDefinitionId } = appContext;
  for (const componentRef of componentRefs) {
    await editorSDK.components.remove(appDefinitionId, { componentRef });
  }
}

export async function removeControllerConnectedComponents(
  appContext: EditorAppContext,
  controllerRef: ComponentRef,
): Promise<void> {
  const componentsRefs = await getControllerConnectedComponents(
    appContext,
    controllerRef,
  );
  await removeComponents(appContext, componentsRefs);
}

export function getControllerConnectedComponents(
  appContext: EditorAppContext,
  controllerRef: ComponentRef,
): Promise<ComponentRef[]> {
  const { editorSDK, appDefinitionId } = appContext;
  return editorSDK.controllers.listConnectedComponents(appDefinitionId, {
    controllerRef,
  });
}

export async function removeController(
  appContext: EditorAppContext,
  controllerRef: ComponentRef,
): Promise<void> {
  const { editorSDK, appDefinitionId } = appContext;
  await editorSDK.components.remove(appDefinitionId, {
    componentRef: controllerRef,
  });
}
