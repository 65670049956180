import { ComponentRef, PageRef } from '@wix/platform-editor-sdk';

import { getSearchAppControllerComponentDefinition } from '../componentDefinitions';
import { ControllerType, EditorAppContext, WixCodeRole } from '../types';
import {
  findControllerByType,
  getControllerByType,
  getControllersByType,
} from '../editorSDKHelpers';

export async function connectControllerWithSearchBox(
  appContext: EditorAppContext,
  controllerRef: ComponentRef,
  searchBoxRef: ComponentRef,
): Promise<void> {
  try {
    const { appDefinitionId, editorSDK } = appContext;
    await editorSDK.controllers.connect(appDefinitionId, {
      controllerRef,
      connectToRef: searchBoxRef,
      role: WixCodeRole.SearchBox,
    });
  } catch (ex) {
    console.error('error in editor.SDK.controllers.connect()', ex);
  }
}

export async function connectControllerWithSearchResults(
  appContext: EditorAppContext,
  controllerRef: ComponentRef,
  searchResultsRef: ComponentRef,
): Promise<void> {
  const { appDefinitionId, editorSDK } = appContext;
  await editorSDK.controllers.connect(appDefinitionId, {
    controllerRef,
    connectToRef: searchResultsRef,
    role: WixCodeRole.SearchResults,
  });
}

export async function addSearchAppController(
  appContext: EditorAppContext,
  pageRef?: PageRef,
): Promise<ComponentRef> {
  const { editorSDK, appDefinitionId } = appContext;
  if (!pageRef) {
    // NOTE: use header as master page ref, to add controller to all pages.
    // we could not use direct `masterPage` ref, because it will brake logic with popups
    pageRef = await appContext.editorSDK.siteSegments.getHeader(
      appDefinitionId,
    );
  }

  return editorSDK.components.add(appDefinitionId, {
    componentDefinition: getSearchAppControllerComponentDefinition({
      appDefinitionId: appContext.appDefinitionId,
    }),
    pageRef,
  });
}

function getSearchAppControllerClassic(
  appContext: EditorAppContext,
  pageRef: PageRef,
  componentRef: ComponentRef,
): Promise<ComponentRef | undefined> {
  return getControllerByType(appContext, ControllerType.SearchApp, pageRef);
}

async function getSearchAppControllerEditorX(
  appContext: EditorAppContext,
  pageRef: PageRef,
  componentRef: ComponentRef,
): Promise<ComponentRef | undefined> {
  try {
    const { editorSDK, appDefinitionId } = appContext;
    const connectableControllers = await editorSDK.document.controllers.listConnectableControllers(
      appDefinitionId,
      { componentRef },
    );
    return connectableControllers.length
      ? connectableControllers[0].controllerRef
      : undefined;
  } catch (ex) {
    // TODO report to sentry maybe
    console.log(
      'Error while calling SDK.document.controllers.listConnectableControllers',
      ex,
    );
  }
}

export async function getSearchAppControllers(appContext: EditorAppContext) {
  return getControllersByType(appContext, ControllerType.SearchApp);
}

export async function isComponentConnected(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
): Promise<boolean> {
  const { editorSDK, appDefinitionId } = appContext;
  const connections = await editorSDK.document.controllers.listConnections(
    appDefinitionId,
    { componentRef },
  );
  const controllers = connections.map(connection => connection.controllerRef);

  const controllerRef = await findControllerByType(
    appContext,
    controllers,
    ControllerType.SearchApp,
  );
  return !!controllerRef;
}

export async function connectSearchBox(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
) {
  const { editorSDK, appDefinitionId, isEditorX } = appContext;
  const pageRef = await editorSDK.components.getPage(appDefinitionId, {
    componentRef,
  });

  const getSearchAppController = isEditorX
    ? getSearchAppControllerEditorX
    : getSearchAppControllerClassic;

  let controllerRef = await getSearchAppController(
    appContext,
    pageRef,
    componentRef,
  );

  if (!controllerRef) {
    controllerRef = await addSearchAppController(appContext, pageRef);
  }

  return connectControllerWithSearchBox(
    appContext,
    controllerRef,
    componentRef,
  );
}
